import React, { Component } from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import '../scss/header.scss'


export default class Header extends Component {
  render() {
    return (
      <>
        <header className="header" ref="headerElem">
          <div className="wrapper">
            <div className="logo">
              <AniLink cover duration={1} bg="#f5d0d0" direction="left" to="/">
                KARY.
              </AniLink>
            </div>

            <nav>
              <ul>
                <li><AniLink cover duration={1} bg="#f5d0d0" direction="up" to="/">Accueil</AniLink></li>
                <li><AniLink cover duration={1} bg="#f5d0d0" direction="bottom" to="/a-propos">A propos</AniLink></li>
                <li><a href="/#portfolio" target="_blank" rel="noopener noreferrer">Portfolio</a></li>
                <li><a href={'/CV_KaryLE.pdf'} target="_blank" rel="noopener noreferrer" className="btn btn--special">Télécharger mon CV</a></li>
              </ul>
            </nav>
            <div className="responsive__menu-trigger" ref="menuResponsiveTrigger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>
        <nav className="menu__responsive" ref="menuResponsive">
          <div className="close__btn" ref="menuResponsiveClose"></div>
          <ul>
            <li><AniLink cover duration={1} bg="#f5d0d0" direction="up" to="/">Accueil</AniLink></li>
            <li><AniLink cover duration={1} bg="#f5d0d0" direction="bottom" to="/a-propos">A propos</AniLink></li>
            <li><a href="https://www.behance.net/karyle" target="_blank" rel="noopener noreferrer">Portfolio</a></li>
            <li><a href={'/CV_KaryLE.pdf'} target="_blank" rel="noopener noreferrer" className="btn btn--special">Télécharger mon CV</a></li>
          </ul>
        </nav>
      </>
    )
  }

  componentDidMount() {
    const headerElem = this.refs.headerElem

    window.addEventListener('scroll', (e) => {
      if (window.scrollY > 0) {
        headerElem.classList.add('fixed')
      } else {
        headerElem.classList.remove('fixed')
      }
    })

    // Menu responsive

    const menuResponsive = this.refs.menuResponsive
    const menuResponsiveTrigger = this.refs.menuResponsiveTrigger
    const menuResponsiveClose = this.refs.menuResponsiveClose

    menuResponsiveTrigger.addEventListener('click', () => {
      menuResponsive.classList.add('opened')
    })

    menuResponsiveClose.addEventListener('click', () => {
      menuResponsive.classList.remove('opened')
    })
  }
}
