import React, { Component } from 'react'

import '../scss/footer.scss'

import svgBehance from '../svg/behance.svg'
import svgDribbble from '../svg/dribbble.svg'
import svgEmail from '../svg/email.svg'
import svgLinkedin from '../svg/linkedin.svg'

const scrollToElement = require('scroll-to-element')


export default class Footer extends Component {
  render() {
    return (
      <>
        <section className="block__contact" ref="blockContact">
            <div className="wrapper">
            <h2 className="contact__title">
                Contactez-moi !
            </h2>

            <ul className="contact__social">
                <li><a href="mailto:kary.le97@gmail.com"><img src={svgEmail} alt="E-mail"/></a></li>
                <li><a href="https://www.behance.net/karyle" target="_blank" rel="noopener noreferrer"><img src={svgBehance} alt="Behance"/></a></li>
                <li><a href="https://dribbble.com/karyle" target="_blank" rel="noopener noreferrer"><img src={svgDribbble} alt="Dribbble"/></a></li>
                <li><a href="https://www.linkedin.com/in/karyle/" target="_blank" rel="noopener noreferrer"><img src={svgLinkedin} alt="LinkedIn"/></a></li>
            </ul>
            </div>
        </section>

        <svg ref="contactScrollBtn" className="svgBadge" width="237" height="241" viewBox="0 0 237 241" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g className="text">
            <path d="M14.3968 88.5115C15.6568 89.0312 16.5282 89.7975 17.0109 90.8103C17.4895 91.8158 17.5405 92.99 17.1639 94.3327C16.7557 95.7882 15.9065 96.8089 14.6164 97.3948C13.3278 97.975 11.8094 98.0186 10.0612 97.5256L8.87693 97.1916C7.73214 96.8687 6.78247 96.3817 6.02794 95.7306C5.27498 95.0738 4.77252 94.3051 4.52054 93.4243C4.26292 92.542 4.27257 91.6071 4.54948 90.6198C4.91659 89.311 5.57747 88.365 6.53212 87.7819C7.48113 87.1973 8.61954 87.0222 9.94736 87.2566L9.48927 88.8899C8.46845 88.7542 7.67165 88.8613 7.09886 89.2111C6.52765 89.5553 6.12337 90.1505 5.88602 90.9968C5.59486 92.0348 5.74969 92.9582 6.3505 93.7669C6.9529 94.5699 7.96183 95.1711 9.37732 95.5703L10.5701 95.9066C11.9066 96.2836 13.0479 96.3041 13.9941 95.9682C14.9403 95.6324 15.5542 94.9623 15.8358 93.9581C16.089 93.0555 16.0798 92.3071 15.8083 91.713C15.5327 91.1118 14.9095 90.589 13.9387 90.1447L14.3968 88.5115Z" fill="#D35656"/>
            <path d="M18.5745 74.7489C17.7532 74.3091 17.1 73.7532 16.6148 73.0812C16.1323 72.4041 15.8658 71.683 15.8151 70.9181C15.7672 70.148 15.9402 69.3932 16.3341 68.6537C16.9429 67.5108 17.8297 66.7993 18.9946 66.5192C20.1622 66.2339 21.402 66.4426 22.714 67.1453L22.8147 67.1992C23.6308 67.6363 24.2803 68.1868 24.7631 68.8509C25.2435 69.5069 25.5088 70.224 25.5591 71.002C25.6122 71.7749 25.4376 72.5388 25.0355 73.2939C24.4295 74.4316 23.5427 75.1431 22.375 75.4284C21.2101 75.7085 19.9769 75.5 18.6752 74.8029L18.5745 74.7489ZM19.5135 73.3973C20.4432 73.8953 21.3039 74.0804 22.0955 73.9527C22.8899 73.8198 23.4799 73.3913 23.8656 72.6673C24.2539 71.9381 24.2784 71.2068 23.9388 70.4732C23.5941 69.7369 22.9078 69.0935 21.8799 68.543C20.9605 68.0506 20.0996 67.8721 19.2973 68.0074C18.4926 68.1347 17.8974 68.5604 17.5118 69.2844C17.1344 69.9929 17.1116 70.7151 17.4432 71.4511C17.7748 72.187 18.4649 72.8358 19.5135 73.3973Z" fill="#D35656"/>
            <path d="M27.3949 51.205L28.3524 51.9223C28.105 50.7796 28.3518 49.7542 29.0926 48.8462C30.3632 47.2891 31.8806 47.2228 33.6447 48.6475L38.503 52.6334L37.4751 53.8932L32.61 49.9017C32.0763 49.4714 31.5845 49.2725 31.1345 49.305C30.6882 49.333 30.2594 49.5989 29.8483 50.1028C29.5149 50.5114 29.331 50.9593 29.2965 51.4464C29.262 51.9335 29.3511 52.4082 29.5637 52.8706L34.8025 57.1688L33.7746 58.4286L26.4225 52.3967L27.3949 51.205Z" fill="#D35656"/>
            <path d="M41.3355 32.6773L42.8466 34.4149L44.1831 33.2462L45.0078 34.1945L43.6713 35.3632L47.5413 39.8133C47.7913 40.1007 48.0392 40.2653 48.2852 40.307C48.5273 40.3444 48.7917 40.2377 49.0784 39.987C49.2196 39.8635 49.3906 39.6673 49.5915 39.3983L50.4566 40.3931C50.2115 40.7631 49.9368 41.0811 49.6324 41.3473C49.0855 41.8255 48.5289 42.0204 47.9626 41.9318C47.3964 41.8432 46.8479 41.4938 46.3173 40.8836L42.4473 36.4336L41.1439 37.5733L40.3192 36.6249L41.6226 35.4852L40.1115 33.7476L41.3355 32.6773Z" fill="#D35656"/>
            <path d="M63.7543 30.6735C63.5765 30.563 63.337 30.3183 63.0358 29.9396C62.8032 31.0057 62.2407 31.8142 61.3482 32.365C60.5504 32.8574 59.7567 33.0373 58.9673 32.9048C58.1798 32.7643 57.573 32.3469 57.147 31.6528C56.629 30.8088 56.5466 29.9577 56.8998 29.0994C57.2549 28.233 58.0134 27.4413 59.1752 26.7243L60.5215 25.8935L60.1307 25.2568C59.8334 24.7724 59.4528 24.4771 58.9889 24.3709C58.5219 24.2597 58.0067 24.378 57.4433 24.7257C56.9496 25.0304 56.6124 25.4107 56.4316 25.8665C56.2507 26.3224 56.2691 26.7276 56.4867 27.0822L55.0956 27.9407C54.8473 27.5362 54.7503 27.059 54.8047 26.5091C54.861 25.951 55.066 25.4114 55.4198 24.8901C55.7786 24.3657 56.2522 23.922 56.8405 23.5589C57.773 22.9834 58.6475 22.7673 59.4642 22.9105C60.2777 23.0488 60.9458 23.5212 61.4684 24.3279L63.758 28.0583C64.2146 28.8024 64.6726 29.3357 65.1318 29.6582L65.2053 29.778L63.7543 30.6735ZM60.8099 31.0344C61.2437 30.7667 61.5861 30.4004 61.8371 29.9357C62.0881 29.471 62.1906 29.005 62.1444 28.5377L61.1238 26.8748L60.0393 27.5441C58.3439 28.5903 57.8012 29.6103 58.4112 30.6041C58.6778 31.0386 59.0308 31.2889 59.4702 31.3551C59.9096 31.4213 60.3562 31.3144 60.8099 31.0344Z" fill="#D35656"/>
            <path d="M79.7538 21.3293C80.2966 21.1247 80.7091 20.7813 80.9915 20.2992C81.2738 19.8171 81.3444 19.3209 81.2031 18.8105L82.6424 18.2681C82.8062 18.7886 82.8128 19.3403 82.6621 19.9231C82.5113 20.5059 82.216 21.0368 81.776 21.5157C81.3415 21.9926 80.8227 22.3447 80.2196 22.572C79.0079 23.0287 77.8917 22.9891 76.8712 22.4533C75.854 21.91 75.0822 20.936 74.5557 19.5314L74.4601 19.2763C74.1351 18.4094 74.0052 17.5786 74.0702 16.7839C74.1353 15.9892 74.3939 15.2937 74.8462 14.6974C75.304 14.0991 75.9386 13.647 76.7501 13.3411C77.748 12.965 78.688 12.952 79.5701 13.3021C80.4577 13.65 81.1023 14.2931 81.5041 15.2312L80.0648 15.7737C79.805 15.2141 79.4088 14.825 78.8763 14.6061C78.3471 14.3798 77.7947 14.3751 77.219 14.5921C76.4459 14.8835 75.9504 15.3896 75.7326 16.1104C75.5181 16.8237 75.6083 17.707 76.0032 18.7604L76.1111 19.0485C76.4957 20.0745 77.003 20.785 77.6329 21.18C78.2628 21.575 78.9697 21.6248 79.7538 21.3293Z" fill="#D35656"/>
            <path d="M95.2932 5.60308L95.7377 7.86251L97.4793 7.51797L97.7219 8.75116L95.9803 9.0957L97.1187 14.8822C97.1922 15.2559 97.3253 15.5223 97.5178 15.6813C97.7092 15.8346 97.9917 15.8743 98.3653 15.8004C98.5492 15.764 98.7954 15.6794 99.1037 15.5468L99.3582 16.8403C98.9612 17.0383 98.5644 17.1766 98.1678 17.255C97.455 17.396 96.8752 17.2867 96.4282 16.9272C95.9813 16.5676 95.6797 15.9912 95.5237 15.1978L94.3852 9.41124L92.6867 9.74725L92.4441 8.51406L94.1426 8.17805L93.6981 5.91862L95.2932 5.60308Z" fill="#D35656"/>
            <path d="M113.825 15.3132C112.536 15.3519 111.475 14.9618 110.642 14.1427C109.808 13.3178 109.37 12.1967 109.328 10.7793L109.319 10.4806C109.291 9.53771 109.444 8.69189 109.779 7.94319C110.12 7.18846 110.607 6.59349 111.238 6.15828C111.876 5.71703 112.572 5.48505 113.328 5.46232C114.564 5.42514 115.536 5.80329 116.246 6.59677C116.956 7.39024 117.333 8.54543 117.378 10.0623L117.399 10.7388L110.959 10.9325C111.011 11.8689 111.306 12.6191 111.844 13.1833C112.389 13.7414 113.065 14.0083 113.873 13.984C114.447 13.9667 114.93 13.8349 115.321 13.5887C115.712 13.3424 116.051 13.0215 116.338 12.626L117.354 13.3692C116.594 14.6173 115.418 15.2652 113.825 15.3132ZM113.368 6.79766C112.712 6.81739 112.169 7.07408 111.738 7.56773C111.307 8.05551 111.052 8.73146 110.972 9.59558L115.733 9.45234L115.73 9.32935C115.658 8.50495 115.417 7.87326 115.005 7.43427C114.593 6.98943 114.047 6.77722 113.368 6.79766Z" fill="#D35656"/>
            <path d="M131.573 14.39L137.071 15.2415L136.869 16.5532L129.408 15.3977L129.59 14.2163L135.836 8.13089L130.729 7.33992L130.934 6.01087L138.056 7.11392L137.881 8.25186L131.573 14.39Z" fill="#D35656"/>
            <path d="M166.56 15.5311L166.09 16.4766C167.096 16.095 168.116 16.1904 169.152 16.7629C170.316 17.4063 170.863 18.2909 170.793 19.4168C171.29 19.1693 171.827 19.0442 172.403 19.0414C172.985 19.0415 173.578 19.2088 174.183 19.5432C176.009 20.5522 176.405 22.0331 175.372 23.9859L172.303 29.5702L170.88 28.7837L173.902 23.2842C174.23 22.6885 174.338 22.1693 174.227 21.7264C174.119 21.2783 173.744 20.8772 173.103 20.5229C172.575 20.231 172.049 20.1479 171.525 20.2735C171.004 20.394 170.569 20.7057 170.219 21.2086L167.18 26.7389L165.749 25.9482L168.75 20.4872C169.416 19.2753 169.157 18.3421 167.972 17.6874C167.039 17.1716 166.182 17.2167 165.401 17.8227L162.056 23.9076L160.633 23.1211L165.214 14.7871L166.56 15.5311Z" fill="#D35656"/>
            <path d="M188.543 34.8467C189.173 34.1606 189.873 33.6668 190.644 33.3652C191.418 33.0677 192.181 32.9907 192.933 33.1343C193.688 33.2818 194.374 33.6399 194.989 34.2084C195.94 35.087 196.405 36.1268 196.384 37.3277C196.367 38.5326 195.856 39.683 194.849 40.7791L194.772 40.8632C194.145 41.545 193.451 42.0365 192.689 42.3376C191.935 42.6385 191.177 42.7153 190.413 42.5681C189.653 42.4249 188.959 42.063 188.331 41.4826C187.384 40.6079 186.919 39.5681 186.936 38.3632C186.957 37.1623 187.467 36.0182 188.466 34.9308L188.543 34.8467ZM189.613 36.0983C188.899 36.875 188.505 37.6635 188.43 38.4638C188.36 39.2681 188.626 39.9486 189.229 40.5052C189.835 41.0658 190.536 41.2737 191.329 41.1291C192.126 40.9801 192.919 40.4762 193.708 39.6175C194.413 38.8495 194.801 38.0591 194.872 37.2465C194.95 36.4336 194.688 35.7488 194.085 35.1922C193.496 34.6476 192.804 34.4434 192.01 34.5798C191.217 34.7162 190.418 35.2223 189.613 36.0983Z" fill="#D35656"/>
            <path d="M201.394 55.0676L200.387 53.7904L207.841 47.8852L208.848 49.1624L201.394 55.0676ZM209.737 46.2152C209.944 46.0515 210.167 45.9754 210.407 45.9869C210.651 46.0029 210.871 46.1352 211.067 46.3838C211.263 46.6323 211.34 46.8774 211.299 47.1191C211.259 47.3607 211.135 47.5634 210.928 47.7272C210.722 47.8909 210.499 47.9629 210.259 47.9432C210.02 47.9235 209.802 47.7893 209.607 47.5408C209.411 47.2923 209.331 47.049 209.367 46.811C209.407 46.5775 209.53 46.379 209.737 46.2152Z" fill="#D35656"/>
            <path d="M222.547 74.1985L221.621 71.5428C221.459 71.078 220.978 70.874 220.547 71.0252L212.585 73.8166C212.12 73.9794 211.904 74.4277 212.066 74.8924L212.992 77.5482C213.143 77.9797 213.602 78.2286 214.066 78.0657L222.029 75.2744C222.46 75.1232 222.697 74.6301 222.547 74.1985ZM213.856 75.7551C213.694 75.2903 213.91 74.8421 214.375 74.6792C214.806 74.528 215.287 74.732 215.449 75.1968C215.599 75.6284 215.362 76.1215 214.93 76.2727C214.466 76.4355 214.007 76.1866 213.856 75.7551ZM230.499 81.842C232.954 80.9813 232.664 79.2948 232.386 78.4981C232.143 77.801 230.712 77.9674 230.068 78.044C229.269 78.1007 228.481 78.1906 227.81 77.8671C226.345 77.1886 224.788 77.1011 223.031 76.2269C222.908 76.1954 222.797 76.1971 222.697 76.232L215.598 78.721C215.399 78.7907 215.291 79.0149 215.327 79.2257C215.512 79.7568 215.457 80.5586 215.409 81.1712C215.315 82.3963 215.203 83.8887 215.758 85.4821L215.793 85.5817C216.291 87.0092 216.881 88.7022 218.075 89.0288C218.577 89.188 219.098 89.08 219.637 88.7046C220.398 89.0712 221.552 88.9646 222.322 88.3967C223.282 88.6935 224.4 88.3761 225.047 87.7766C225.584 88.0354 226.307 88.08 226.893 87.8374C227.921 87.4769 228.549 86.2881 228.167 85.1926L226.987 81.8066C228.008 81.7465 229.105 82.3305 230.499 81.842Z" fill="#D35656"/>
            <path d="M223.422 153.288C222.162 152.767 221.292 151.999 220.811 150.986C220.334 149.98 220.284 148.805 220.663 147.463C221.073 146.008 221.924 144.989 223.215 144.405C224.504 143.826 226.023 143.785 227.77 144.28L228.954 144.616C230.098 144.941 231.047 145.429 231.801 146.081C232.553 146.739 233.054 147.508 233.305 148.39C233.561 149.272 233.55 150.207 233.272 151.194C232.903 152.502 232.241 153.447 231.285 154.029C230.335 154.612 229.197 154.786 227.869 154.55L228.33 152.917C229.35 153.054 230.147 152.948 230.721 152.599C231.292 152.256 231.697 151.661 231.936 150.815C232.229 149.777 232.075 148.854 231.475 148.044C230.874 147.24 229.866 146.638 228.451 146.237L227.259 145.899C225.923 145.52 224.782 145.498 223.835 145.832C222.888 146.167 222.273 146.836 221.99 147.84C221.736 148.742 221.744 149.49 222.015 150.085C222.289 150.686 222.912 151.21 223.882 151.656L223.422 153.288Z" fill="#D35656"/>
            <path d="M219.261 167.026C220.083 167.465 220.737 168.019 221.224 168.691C221.707 169.367 221.975 170.087 222.027 170.852C222.076 171.622 221.904 172.377 221.512 173.118C220.905 174.262 220.019 174.974 218.855 175.257C217.688 175.544 216.448 175.337 215.134 174.637L215.034 174.583C214.217 174.147 213.566 173.598 213.082 172.935C212.601 172.279 212.334 171.563 212.283 170.785C212.228 170.012 212.402 169.248 212.803 168.492C213.407 167.353 214.292 166.64 215.459 166.353C216.624 166.071 217.857 166.277 219.16 166.972L219.261 167.026ZM218.324 168.379C217.394 167.883 216.533 167.699 215.741 167.828C214.947 167.963 214.358 168.392 213.974 169.117C213.586 169.846 213.563 170.578 213.904 171.311C214.25 172.047 214.937 172.689 215.966 173.238C216.886 173.728 217.748 173.906 218.55 173.769C219.354 173.64 219.949 173.213 220.333 172.489C220.709 171.78 220.731 171.057 220.398 170.322C220.065 169.587 219.374 168.939 218.324 168.379Z" fill="#D35656"/>
            <path d="M210.456 190.652L209.499 189.933C209.745 191.076 209.497 192.101 208.754 193.008C207.481 194.563 205.964 194.627 204.202 193.2L199.35 189.206L200.38 187.948L205.239 191.947C205.772 192.378 206.263 192.578 206.713 192.546C207.159 192.519 207.589 192.254 208.001 191.75C208.335 191.342 208.519 190.895 208.554 190.408C208.59 189.921 208.501 189.446 208.29 188.983L203.058 184.677L204.087 183.418L211.43 189.462L210.456 190.652Z" fill="#D35656"/>
            <path d="M196.498 209.005L194.979 207.274L193.648 208.448L192.819 207.504L194.15 206.329L190.26 201.896C190.009 201.61 189.761 201.447 189.514 201.406C189.272 201.37 189.008 201.478 188.723 201.73C188.582 201.854 188.412 202.051 188.212 202.321L187.343 201.33C187.586 200.959 187.859 200.639 188.162 200.372C188.707 199.891 189.263 199.694 189.83 199.78C190.396 199.866 190.946 200.213 191.48 200.821L195.37 205.253L196.668 204.108L197.497 205.052L196.199 206.198L197.718 207.929L196.498 209.005Z" fill="#D35656"/>
            <path d="M174.015 211.093C174.192 211.203 174.432 211.448 174.733 211.827C174.966 210.76 175.528 209.952 176.421 209.401C177.218 208.909 178.012 208.729 178.802 208.861C179.589 209.002 180.196 209.419 180.622 210.113C181.14 210.957 181.222 211.809 180.869 212.667C180.514 213.533 179.755 214.325 178.594 215.042L177.247 215.873L177.638 216.509C177.935 216.994 178.316 217.289 178.78 217.395C179.247 217.506 179.762 217.388 180.326 217.04C180.819 216.736 181.156 216.356 181.337 215.9C181.518 215.444 181.5 215.039 181.282 214.684L182.673 213.825C182.922 214.23 183.019 214.707 182.964 215.257C182.908 215.815 182.703 216.355 182.349 216.876C181.99 217.4 181.517 217.844 180.928 218.207C179.996 218.783 179.121 218.999 178.305 218.856C177.491 218.717 176.823 218.245 176.3 217.438L174.011 213.708C173.554 212.964 173.096 212.431 172.637 212.108L172.564 211.988L174.015 211.093ZM176.959 210.732C176.525 211 176.183 211.366 175.932 211.831C175.681 212.295 175.578 212.761 175.624 213.229L176.645 214.891L177.73 214.222C179.425 213.176 179.968 212.156 179.358 211.162C179.091 210.728 178.738 210.477 178.299 210.411C177.859 210.345 177.413 210.452 176.959 210.732Z" fill="#D35656"/>
            <path d="M158.015 220.437C157.472 220.642 157.06 220.985 156.777 221.467C156.495 221.949 156.424 222.445 156.566 222.956L155.126 223.498C154.963 222.978 154.956 222.426 155.107 221.843C155.258 221.26 155.553 220.729 155.993 220.25C156.427 219.774 156.946 219.422 157.549 219.194C158.761 218.737 159.877 218.777 160.898 219.313C161.915 219.856 162.687 220.83 163.213 222.235L163.309 222.49C163.634 223.357 163.764 224.188 163.699 224.982C163.634 225.777 163.375 226.472 162.923 227.069C162.465 227.667 161.83 228.119 161.019 228.425C160.021 228.801 159.081 228.814 158.199 228.464C157.311 228.116 156.667 227.473 156.265 226.535L157.704 225.992C157.964 226.552 158.36 226.941 158.893 227.16C159.422 227.386 159.974 227.391 160.55 227.174C161.323 226.883 161.818 226.377 162.036 225.656C162.251 224.943 162.161 224.059 161.766 223.006L161.658 222.718C161.273 221.692 160.766 220.981 160.136 220.586C159.506 220.191 158.799 220.141 158.015 220.437Z" fill="#D35656"/>
            <path d="M142.476 236.149L142.031 233.889L140.29 234.234L140.047 233L141.789 232.656L140.65 226.869C140.577 226.496 140.444 226.229 140.251 226.07C140.06 225.917 139.777 225.877 139.404 225.951C139.22 225.988 138.974 226.072 138.665 226.205L138.411 224.911C138.808 224.713 139.204 224.575 139.601 224.497C140.314 224.356 140.894 224.465 141.341 224.824C141.788 225.184 142.089 225.76 142.245 226.554L143.384 232.34L145.082 232.004L145.325 233.238L143.626 233.574L144.071 235.833L142.476 236.149Z" fill="#D35656"/>
            <path d="M123.944 226.453C125.232 226.414 126.293 226.804 127.127 227.624C127.961 228.448 128.399 229.57 128.441 230.987L128.45 231.286C128.478 232.229 128.325 233.074 127.989 233.823C127.649 234.578 127.162 235.173 126.53 235.608C125.893 236.049 125.196 236.281 124.441 236.304C123.205 236.341 122.232 235.963 121.523 235.169C120.813 234.376 120.436 233.221 120.39 231.704L120.37 231.027L126.81 230.834C126.758 229.897 126.463 229.147 125.925 228.583C125.38 228.025 124.704 227.758 123.896 227.782C123.322 227.8 122.839 227.931 122.448 228.178C122.057 228.424 121.718 228.745 121.43 229.14L120.415 228.397C121.174 227.149 122.351 226.501 123.944 226.453ZM124.401 234.969C125.057 234.949 125.6 234.692 126.031 234.199C126.462 233.711 126.717 233.035 126.797 232.171L122.035 232.314L122.039 232.437C122.111 233.261 122.352 233.893 122.764 234.332C123.176 234.777 123.722 234.989 124.401 234.969Z" fill="#D35656"/>
            <path d="M106.196 227.362L100.698 226.51L100.9 225.198L108.361 226.354L108.179 227.535L101.933 233.621L107.04 234.412L106.835 235.741L99.713 234.638L99.8883 233.5L106.196 227.362Z" fill="#D35656"/>
            <path d="M71.2236 226.22L71.6931 225.275C70.6878 225.657 69.6671 225.561 68.6312 224.989C67.467 224.345 66.9202 223.461 66.9908 222.335C66.4937 222.582 65.9569 222.707 65.3804 222.71C64.7988 222.71 64.2054 222.543 63.6003 222.208C61.7746 221.199 61.3783 219.718 62.4115 217.766L65.4806 212.181L66.9037 212.968L63.8812 218.467C63.5538 219.063 63.4456 219.582 63.5565 220.025C63.6647 220.473 64.0392 220.874 64.6803 221.229C65.2085 221.521 65.7345 221.604 66.2582 221.478C66.779 221.358 67.2144 221.046 67.5644 220.543L70.6039 215.013L72.0347 215.803L69.0333 221.264C68.3672 222.476 68.6265 223.41 69.8112 224.064C70.7446 224.58 71.6018 224.535 72.3828 223.929L75.7271 217.844L77.1502 218.63L72.5698 226.964L71.2236 226.22Z" fill="#D35656"/>
            <path d="M49.2259 206.905C48.5957 207.591 47.8954 208.085 47.1251 208.386C46.3506 208.684 45.5876 208.761 44.8364 208.617C44.0808 208.47 43.3952 208.112 42.7798 207.543C41.8285 206.665 41.3635 205.625 41.3846 204.424C41.4014 203.219 41.9132 202.069 42.9201 200.973L42.9974 200.888C43.6237 200.207 44.3178 199.715 45.0798 199.414C45.8336 199.113 46.5923 199.036 47.3562 199.184C48.1157 199.327 48.8097 199.689 49.4381 200.269C50.385 201.144 50.8501 202.183 50.8333 203.388C50.8121 204.589 50.3021 205.733 49.3032 206.821L49.2259 206.905ZM48.1559 205.653C48.8694 204.877 49.2636 204.088 49.3384 203.288C49.4089 202.483 49.1429 201.803 48.5403 201.246C47.9334 200.686 47.2333 200.478 46.44 200.623C45.6428 200.772 44.8498 201.275 44.061 202.134C43.3554 202.902 42.9675 203.692 42.8973 204.505C42.8189 205.318 43.0809 206.003 43.6835 206.559C44.2732 207.104 44.9648 207.308 45.7584 207.172C46.5521 207.035 47.3512 206.529 48.1559 205.653Z" fill="#D35656"/>
            <path d="M36.3752 186.684L37.3815 187.961L29.9274 193.866L28.9211 192.589L36.3752 186.684ZM28.0318 195.536C27.8251 195.7 27.6016 195.776 27.3613 195.765C27.1174 195.749 26.8976 195.616 26.7018 195.368C26.5059 195.119 26.4285 194.874 26.4693 194.633C26.5101 194.391 26.6339 194.188 26.8406 194.024C27.0473 193.861 27.2703 193.789 27.5096 193.808C27.7489 193.828 27.9665 193.962 28.1623 194.211C28.3581 194.459 28.4379 194.703 28.4016 194.941C28.3617 195.174 28.2385 195.373 28.0318 195.536Z" fill="#D35656"/>
            <path d="M15.2219 167.568L16.1479 170.223C16.3099 170.688 16.7906 170.892 17.2219 170.741L25.1842 167.95C25.6487 167.787 25.8647 167.338 25.7027 166.874L24.7768 164.218C24.6263 163.786 24.1673 163.538 23.7028 163.7L15.7404 166.492C15.3091 166.643 15.0715 167.136 15.2219 167.568ZM23.9127 166.011C24.0747 166.476 23.8587 166.924 23.3942 167.087C22.9629 167.238 22.4823 167.034 22.3202 166.569C22.1698 166.138 22.4074 165.645 22.8387 165.494C23.3032 165.331 23.7622 165.58 23.9127 166.011ZM7.2702 159.924C4.81513 160.785 5.10526 162.471 5.38303 163.268C5.62608 163.965 7.05731 163.799 7.70078 163.722C8.50011 163.665 9.28787 163.576 9.95912 163.899C11.4243 164.578 12.9813 164.665 14.7382 165.539C14.8608 165.571 14.9719 165.569 15.0715 165.534L22.1713 163.045C22.3703 162.975 22.4783 162.751 22.4421 162.54C22.2569 162.009 22.3124 161.208 22.3595 160.595C22.4536 159.37 22.5662 157.877 22.0107 156.284L21.9759 156.184C21.4783 154.757 20.888 153.064 19.6944 152.737C19.1921 152.578 18.6713 152.686 18.132 153.062C17.3713 152.695 16.217 152.802 15.447 153.37C14.4872 153.073 13.3692 153.39 12.7219 153.99C12.1849 153.731 11.4619 153.686 10.8763 153.929C9.84787 154.289 9.21984 155.478 9.60178 156.574L10.7823 159.96C9.76077 160.02 8.66361 159.436 7.2702 159.924Z" fill="#D35656"/>
          </g>
          <path className="thumb" d="M90.5 115H75.5C72.875 115 71 117.062 71 119.5V164.5C71 167.125 72.875 169 75.5 169H90.5C92.9375 169 95 167.125 95 164.5V119.5C95 117.062 92.9375 115 90.5 115ZM83 161.5C80.375 161.5 78.5 159.625 78.5 157C78.5 154.562 80.375 152.5 83 152.5C85.4375 152.5 87.5 154.562 87.5 157C87.5 159.625 85.4375 161.5 83 161.5ZM143 88.375C143 74.5 134 73 129.5 73C125.562 73 123.875 80.5 123.125 83.875C122 88 121.062 92.125 118.25 94.9375C112.25 101.125 109.062 108.812 101.562 116.125C101.188 116.688 101 117.25 101 117.812V157.938C101 159.062 101.938 160 103.062 160.188C106.062 160.188 110 161.875 113 163.188C119 165.812 126.312 169 135.312 169H135.875C143.938 169 153.5 169 157.25 163.562C158.938 161.312 159.312 158.5 158.375 155.125C161.562 151.938 163.062 145.938 161.562 141.062C164.75 136.75 165.125 130.562 163.25 126.25C165.5 124 167 120.438 166.812 117.062C166.812 111.25 161.938 106 155.75 106H136.625C138.125 100.75 143 96.25 143 88.375Z" fill="#D35656"/>
          <defs>
            <clipPath id="clip0">
              <rect width="237" height="241" fill="white"/>
            </clipPath>
          </defs>
        </svg>

      </>
    )
  }

  componentDidMount() {

    this.refs.contactScrollBtn.addEventListener('click', () => {
      scrollToElement(this.refs.blockContact, {
        offset: 0,
        ease: 'inOutCube',
        duration: 800
      })
    })
  }
}
